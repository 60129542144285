export function find() {
  return {
    code: "OK",
    message: "La operación se completó exitosamente.",
    data: {
      id: 1,
      name: "Movistar",
      order: 0,
      active: true,
      steps: [
        {
          id: 1,
          name: "",
          details: "",
          order: 1,
          step: 1,
          maxAnswers: 1,
          required: true,
          answers: [
            {
              id: 1,
              name: "Número telefónico",
              key: "phone",
              type: "string",
              component: "phone",
            },
          ],
          filters: [],
        },
        {
          id: 2,
          name: "",
          details: "",
          order: 2,
          step: 1,
          maxAnswers: 1,
          required: true,
          answers: [
            {
              id: 2,
              name: "Acepto los términos y condiciones del servicio",
              key: "terms-privacy",
              type: "boolean",
              component: "radio",
            }
          ],
          filters: [],
        }
      ],
    },
  };
}
