<template>
  <div>
    <Toast
      position="top-right"
      :breakpoints="{ '920px': { width: '100%', right: '0', left: '0' } }"
    />
    <Menubar :model="items">
      <template #start>
        <router-link :to="{ name: 'Home' }">
          <img
            alt="logo"
            src="./assets/images/Isologo_Leadgenios_Digital-04.png"
            class="sm:pl-4 md:pl-6 lg:pl-6 isologo"
          />
        </router-link>
      </template>
      <template #end>
        <img alt="logo" src="./assets/images/logo.png" height="55" />
      </template>
    </Menubar>
    <router-view class="view" />
    <section id="terms">
      <div class="row bg-dark-blue justify-content-center">
        <div class="col-11 text-center py-5">
          <small class="text-white justify-center">
            El titular de los datos personales autoriza a Colombia
            Telecomunicaciones S.A. ESP BIC -- Telefonica- (Av. Suba No.114 A-55 de
            Bogotá, tel. 601 7050000) para recolectar, almacenar, conservar, usar,
            suprimir, actualizar, compartir y circular a terceros, sus datos
            personales de orden demográfico, económico, biométricos, de servicios,
            comercial, de contacto, y de localización; para obtención y suministro
            de información relativa al cumplimiento de sus obligaciones y el cálculo
            de riesgo económico o crediticio (de manera irrevocable), para campañas
            comerciales, publicitarias o de cobranza a través de los siguientes
            medios: correo electrónico, mensajes cortos, llamadas telefónicas,
            mensajería por aplicaciones o web, y demás que en el futuro implemente
            Telefónica como canales de contacto; para la publicación de directorios
            telefónicos, la prevención y control de fraudes, y para beneficio propio
            o de terceros con los que Telefónica haya celebrado convenio para envío
            y recepción de cualquier tipo de información, con fines comerciales o
            publicitarios en Colombia o en el exterior. La información para el
            cálculo de riesgo crediticio podrá ser consultada en cualquier operador
            de banco de datos, por las entidades financieras con las cuales
            Telefónica celebre convenios comerciales en favor de los titulares. Los
            datos serán tratados de conformidad con las Políticas para el
            Tratamiento de Datos Personales que se encuentran a disposición de los
            interesados y/o titulares de los datos en la página web www.movistar.co
            El titular de los datos tiene derecho de conocer, actualizar,
            rectificar, suprimir los datos, y revocar la autorización salvo las
            excepciones legales. Los datos biométricos son datos sensibles y el
            titular no está obligado a autorizar su tratamiento, estos serán usados
            para verificación de identidad y suscripción de contratos.
          </small>
        </div>
      </div>
    </section>
    <div class="bg-dark-gray  row">
      <div class="col-5 text-right">
        <img
          src="./assets/images/logo.png"
          class="footer-img vertical-center"
        />
      </div>
      <div class="col-6 d-flex align-items-center text-left">
        <span class="text-white">
          2024 © Movistar Copyright - Todos los derechos reservados.
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import Menubar from "primevue/menubar";
import Toast from "primevue/toast";
import { useToast } from "primevue/usetoast";
export default {
  components: {
    Menubar: Menubar,
    Toast: Toast,
  },
  data() {
    return {
      name: process.env.VUE_APP_TITLE,
      fbPage: process.env.VUE_APP_FB_PAGE,
      items: [],
    };
  },
  setup() {
    const toast = useToast();
    const year = new Date().getUTCFullYear();
    return {
      toast,
      year,
    };
  },
};
</script>

<style>
.list-footer {
  list-style-type: none;
  text-align: left;
}

.list {
  color: #058fcc;
  font-size: 12px;
}

.p {
  color: white;
  font-size: 16px;
  margin: 1em;
}

.p-menubar-end {
  padding-right: 3rem;
}

.bg-black {
  background-color: #000;
}

i {
  font-size: 19px;
}

.view {
  width: 100%;
}

.p-menubar {
  position: fixed !important;
  z-index: 100;
  width: 100%;
  top: 0;
  background: #105a87 !important;
  border-color: #105a87 !important;
}

.p-menubar .p-menubar-button {
  position: absolute;
  right: 5%;
  display: none !important;
}

.p-menubar .p-menubar-button .pi {
  font-size: 2rem;
}

/* .p-menubar .p-menubar-root-list {
  position: absolute;
  left: 75%;
} */

@media (min-width: 1825px) {
  .footer {
    padding: 0 25rem;
  }
}

.p-fb {
  margin-top: 0;
}
.bg-dark-gray {
  background: #4e4e4e;
  opacity: 0.7;
}

.footer-img {
  height: 75%;
}

img.isologo {
  max-width: 170px;
  max-height: 40px;
}

@media (max-width: 1825px) {
  .isologo {
    padding-right: 1rem;
  }
}

@media (max-width: 580px) {
  .p-menubar-end {
    padding-right: 0;
  }
}
</style>
