
import { defineComponent, ref } from "vue";
import Card from "primevue/card";
import Button from "primevue/button";
import TypePhone from "../components/TypePhone.vue";
import TypeRadioButton from "../components/TypeRadioButton.vue";
import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordiontab";
import Dialog from "primevue/dialog";
import * as LeadServices from "../api/helpers/Lead";
import * as movistarServices from "../api/helpers/MovistarLead";
import * as FacebookServices from "../api/helpers/Facebook";
import Questionnaire from "../models/Questionnaire";
import * as QuestionnaireServices from "../api/helpers/Questionnaire";
import _ from "underscore";
import { useGtm } from "vue-gtm";
import { getCurrentInstance } from "vue";
export default defineComponent({
  name: "Home",
  components: {
    Card,
    Button,
    TypePhone,
    Accordion,
    AccordionTab,
    TypeRadioButton,
    Dialog,
  },
  data() {
    return {
      submitted: false,
      isvalid: true,
      display: false,
      leadId: sessionStorage.getItem("leadId"),
      applicationId: sessionStorage.getItem("applicationId"),
      phoneLength: process.env.VUE_APP_PHONE_LENGTH,
      birthdate: new Date(),
      answers: {} as any,
      spinnerColor: process.env.VUE_APP_SPINNER_COLOR,
      spinnerTextColor: process.env.VUE_APP_SPINNER_TEXT_COLOR,
      items: [
        {
          id: 1,
          name: "switchTo",
          content: [
            {
              id: 0,
              gigas: "65 GB",
              gigasCte: "100 GB ",
              gigasCteDetails: "con Movistar Total",
              onlineBenefits: "50% Dto. Exclusivo Online",
              price: "$22.495",
              priceDetails: " x2 meses",
              offer: "Primer Mes GRATIS. ",
              offerDetails: "Luego $44.990",
              benefits: [
                {
                  id: 0,
                  title: "",
                  details: "7 GB para compartir",
                },
                {
                  id: 1,
                  title: "",
                  details: "120 Minutos a Venezuela",
                },
              ],
              elegiapps: "Elige hasta 4 Apps disponibles",
            },
            {
              id: 1,
              gigas: "90 GB",
              gigasCte: "135 GB ",
              gigasCteDetails: "con Movistar Total",
              onlineBenefits: "50% Dto. Exclusivo Online",
              price: "$27.495",
              priceDetails: " x2 meses",
              offer: "Primer Mes GRATIS. ",
              offerDetails: "Luego $54.990",
              benefits: [
                {
                  id: 0,
                  title: "",
                  details: "9 GB para compartir",
                },
                {
                  id: 1,
                  title: "",
                  details: "150 Minutos a Venezuela",
                },
              ],
              elegiapps: "Elige hasta 7 Apps disponibles",
            },
            {
              id: 2,
              gigas: "125 GB",
              gigasCte: "188 GB ",
              gigasCteDetails: "con Movistar",
              onlineBenefits: "50% Dto. Exclusivo Online",
              price: "$32.995",
              priceDetails: " x2 meses",
              offer: "Primer Mes GRATIS. ",
              offerDetails: "Luego $65.990",
              benefits: [
                {
                  id: 0,
                  title: "",
                  details: "11 GB para compartir",
                },
                {
                  id: 1,
                  title: "",
                  details: "500 Minutos a Venezuela",
                },
              ],
              elegiapps: "Elige hasta 9 Apps disponibles",
            },
            {
              id: 3,
              gigas: "ILIMIDATOS",
              gigasCte: "Navega sin límites",
              gigasCteDetails: "",
              onlineBenefits: "10% Dto. Exclusivo Online",
              price: "$78.291",
              priceDetails: "x11 meses",
              offer: "Primer mes GRATIS. ",
              offerDetails: "Luego $86.990",
              benefits: [
                {
                  id: 0,
                  title: "",
                  details: "19GB para compartir + Pasa Gigas o 29GB con Movistal Total",
                },
                {
                  id: 1,
                  title: "",
                  details: "500 Minutos a Venezuela",
                },
              ],
              elegiapps: "¡Disney+ y Star+ INCLUIDOS en tu plan!",
            },
            {
              id: 4,
              gigas: "ILIMIDATOS",
              gigasCte: "Navega sin límites",
              gigasCteDetails: "",
              onlineBenefits: "Roaming Internacional incluido",
              price: "$121.990",
              priceDetails: "mes. /IVA incluido",
              offer: "",
              offerDetails: "",
              benefits: [
                {
                  id: 0,
                  title: "",
                  details: "36GB para compartir + Pasa Gigas + Roaming internacional 0 54GB con Movistar Total",
                },
                {
                  id: 1,
                  title: "",
                  details: "500 Minutos a América, Latina, España y Venezuela",
                },
                {
                  id: 2,
                  title: "",
                  details: "Movistar Música Lite",
                },
              ],
              elegiapps: "¡Disney+ y Star+ INCLUIDOS en tu plan!",
            },
          ],
        },
      ],
    };
  },
  setup(props) {
    const app = getCurrentInstance();
    const toastTime = app?.appContext.config.globalProperties.$toastTime;
    var loading = ref(true);
    var questionnaire = ref({});
    const questionnaires = ref({
      Questionnaire,
    });
    const getQuestionnaire = async () => {
      questionnaire.value = await QuestionnaireServices.find().data;
    };
    return {
      questionnaire,
      questionnaires,
      getQuestionnaire,
      loading,
      toastTime,
    };
  },
  mounted() {
    sessionStorage.clear();
    localStorage.clear();
    window.scrollTo(0, 0);
    FacebookServices.pageView();
    this.getQuestionnaire();
    this.questionnaires.Questionnaire.prototype = QuestionnaireServices.find().data;
    this.loading = false;
    const urlParams = new URLSearchParams(window.location.search);
    sessionStorage.setItem("SID", urlParams.get("SID") || "");
  },
  methods: {
    register() {
      this.submitted = true;
      this.isvalid = true;
      for (var x of this.questionnaires.Questionnaire.prototype.steps) {
        this.isvalid =
          !_.some(x.answers, (y) => y.valid == false) && this.isvalid;
        for (var y of x.answers) {
          if (!y.valid) {
            this.isvalid = false;
            break;
          }
          Object.assign(this.answers, {
            [y.key]: y.value,
          });
        }
      }
      if (this.isvalid) {
        this.loading = true;
        this.submitted = false;
        if (this.leadId) this.updateLead();
        else this.createLead();
      }
    },
    setValue(obj: any) {
      if (this.questionnaires.Questionnaire.prototype.steps) {
        for (var x of this.questionnaires.Questionnaire.prototype.steps) {
          for (var y of x.answers) {
            if (y.key == obj.answerKey) {
              Object.assign(y, {
                value: obj.value,
              });
              Object.assign(y, {
                valid: obj.valid,
              });
              break;
            }
          }
        }
      }
    },
    createLead() {
      const steps = JSON.parse(sessionStorage.getItem("steps") || "{}");
      if (this.answers) {
        this.answers.termsAndConditions = true;
        this.answers.privacyNotice = true;
      }
      LeadServices.create(this.answers).then(
        (res) => {
          const {
            data: { lead, application, newEmail },
          } = res.data;
          sessionStorage.setItem("newEmail", newEmail ? "1" : "0");
          sessionStorage.setItem("leadId", lead.id);
          sessionStorage.setItem("applicationId", application.id);
          sessionStorage.setItem(
            "steps",
            JSON.stringify(Object.assign(steps, this.answers))
          );
          this.leadId = lead.id;
          this.applicationId = application.id;
          this.sendToMovistar();
        },
        (err) => {
          this.loading = false;
          this.$toast.add({
            severity: "error",
            summary: err,
            life: this.toastTime,
          });
        }
      );
    },
    updateLead() {
      if (this.leadId) {
        const steps = JSON.parse(sessionStorage.getItem("steps") || "{}");
        LeadServices.update(this.answers, this.leadId).then(
          (res) => {
            const newEmail = res.data.data.newEmail;
            if (_.has(this.answers, "email"))
              sessionStorage.setItem("newEmail", newEmail ? "1" : "0");
            sessionStorage.setItem(
              "steps",
              JSON.stringify(Object.assign(steps, this.answers))
            );
          },
          (err) => {
            this.loading = false;
            this.$toast.add({
              severity: "error",
              summary: err,
              life: this.toastTime,
            });
          }
        );
      }
    },
    sendToMovistar() {
      const body = {
        telefonoCliente: this.answers.phone,
        producto: "movil",
        medio: "web",
        microsegmento: "b2c",
        canal: "Leadgenios",
        campania: "Leadgenios",
        tipoLead: "CPL",
        leadId: this.leadId,
        landing: `Leadgenios Pospago lead:${this.leadId}`
      };
      movistarServices.create(body).then(
        (res: any) => {
          sessionStorage.setItem("afresponse",JSON.stringify(res.data));
          this.$router.push("/resultados");
        },
        (err: any) => {
          this.loading = false;
          this.$toast.add({
            severity: "error",
            summary: err,
            life: this.toastTime,
          });
        }
      );
    },
    to(id: any) {
      this.$router.push("/resultados");
    },
  },
});
