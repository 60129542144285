
import { defineComponent, reactive, ref } from "vue";
import { required } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import RadioButton from "primevue/radiobutton";
import * as Catalog from "../api/helpers/Catalog";
import Spinner from "../components/Spinner.vue";

const __default__ = defineComponent({
  name: "TypeRadioButton",
  components: {
    RadioButton,
  },
  props: {
    isRequired: Boolean,
    isSubmitted: Boolean,
    catalog: String,
    answerKey: String,
    textColor: String,
    validationLabel: String
  },
  data() {
    return {
      spinnerColor: process.env.VUE_APP_SPINNER_COLOR,
      spinnerTextColor: process.env.VUE_APP_SPINNER_COLOR,
    };
  },
  setup(props) {
    var loading = ref(true);
    var catalogData = ref({});
    const f = reactive({
      name: null,
    });

    var rules;
    rules = props.isRequired
      ? (rules = {
          name: { required },
        })
      : (rules = {
          name: {},
        });

    if (props.isRequired) rules.name.required = required;

    const v$ = useVuelidate(rules, f);
    return { f, v$, catalogData, loading };
  },
  mounted() {
    var step = JSON.parse(sessionStorage.getItem("steps") || "{}");
    if (
      typeof step === "object" &&
      this.answerKey &&
      Object.prototype.hasOwnProperty.call(step, this.answerKey)
    )
    this.v$.name.$model = step[this.answerKey];
    this.setValues();
  },
  methods: {
    setValues() {
      this.$emit("getValue", {
        value: this.v$.name.$model || undefined,
        valid:
          !(!this.v$.name.$model && this.isRequired) && !this.v$.name.$invalid,
        answerKey: this.answerKey,
      });
    },
  },
});

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "643a4e12": (_ctx.textColor)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__