import axios, { AxiosInstance } from "axios";

export class Movistar {
  static http: AxiosInstance;
  static baseURL = process.env.VUE_APP_BACK_WEBHOOK;

  constructor() {
    Movistar.http = axios.create({
      baseURL: Movistar.baseURL,
      headers: {
        "Content-Type": "application/json"
      }
    });
  }
}

const movistar = new Movistar();

export default movistar;
